import ComponentRepository from './architecture/componentRepository';

document.addEventListener('DOMContentLoaded', function () {
    window.cr = new ComponentRepository({
        'header': () => import('./components/top-navigation'),
        'hero-swiper': () => import('./components/hero-swiper'),
        'short-content-swiper': () => import('./components/short-content-swiper'),
        'image-gallery': () => import('./components/image-gallery'),
        'accordion': () => import('./components/accordion'),
        'top-navigation': () => import('./components/top-navigation'),
        'media-swiper': () => import('./components/media-swiper'),
        'swiper': () => import('../../node_modules/swiper/swiper-bundle.js'),
        'modal': () => import('./components/modal'),
        'map': () => import('./components/map'),
        'slider-swiper': () => import('./components/slider-swiper'),
        'data-baseline': () => import('./components/data-baseline'),
        'article-overview': () => import('./components/article-overview'),
        'factbox-macro': () => import('./components/factbox-macro'),
        /*
        This is an example component that demonstrates how to implement a filterable map. It should not be used in production sites as is but can serve as inspiration.
        'domain-filterable-map': () => import('./components/example-components/example-domain-filterable-map'),
        */
    });
});
